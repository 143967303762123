import { Link } from 'gatsby';
import React, { useState } from 'react';

import './Header.scss';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(isOpen => !isOpen);

  const logoCloseMenu = () =>
    isMobileMenuOpen ? setMobileMenuOpen(false) : null;

  const headerClassName = isMobileMenuOpen
    ? 'header mobile-menu open'
    : 'header';

  const mobileMenuClassName = isMobileMenuOpen
    ? 'mobile-menu open'
    : 'mobile-menu';

  const navbarClassName = isMobileMenuOpen ? 'navbar mobile-open' : 'navbar';

  return (
    <header className={headerClassName}>
      <div className="logo-wrapper">
        <Link to="/" onClick={logoCloseMenu}>
          <div className="logo" />
        </Link>
        <div className={mobileMenuClassName} onClick={toggleMobileMenu}>
          <div className="hamburger" />
          <div className="hamburger" />
          <div className="hamburger" />
        </div>
      </div>
      <nav className={navbarClassName}>
        <Link className="nav-item home" to="/" onClick={toggleMobileMenu}>
          Home
        </Link>
        <Link className="nav-item" to="/results" onClick={toggleMobileMenu}>
          Student Achievements
        </Link>
        <Link
          className="nav-item"
          to="/structure-rates"
          onClick={toggleMobileMenu}
        >
          Structure & Rates
        </Link>
        <Link className="nav-item" to="/about-me" onClick={toggleMobileMenu}>
          About Me
        </Link>
      </nav>
    </header>
  );
};

export default Header;
