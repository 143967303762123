import React from 'react';
import { Link } from 'gatsby';

import './Sidebar.scss';

const Sidebar = () => (
  <aside className="sidebar">
    <div className="vertical-line">
      <div />
      <div />
    </div>
    <div className="links">
      <Link className="link" to="/" activeClassName="selected">
        <span className="number">01</span>
        <div className="text">
          Mission <br />
          Statement
        </div>
      </Link>
      <Link className="link" to="/results" activeClassName="selected">
        <span className="number">02</span>
        <div className="text">
          Student <br />
          Achievements
        </div>
      </Link>
      <Link className="link" to="/structure-rates" activeClassName="selected">
        <span className="number">03</span>
        <div className="text">
          Structure &<br />
          Rates
        </div>
      </Link>
      <Link className="link" to="/about-me" activeClassName="selected">
        <span className="number">04</span>
        <div className="text">About Me</div>
      </Link>
    </div>
  </aside>
);

export default Sidebar;
