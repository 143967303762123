// import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';

import Header from 'components/header/Header';
import Sidebar from 'components/sidebar/Sidebar';
import Footer from 'components/footer/Footer';

import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  useEffect(() => {
    const body = document.querySelector('body');
    body &&
      body.scrollTo({
        top: 0,
        behavior: 'auto',
      });
  }, []);

  return (
    <div className="app-layout">
      <Header />
      <div className="app-content">
        <Sidebar />
        <div className="main-wrapper">
          <main>{children}</main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
