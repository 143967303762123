import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import './Footer.scss';

const copyToClipBoard = (e: React.MouseEvent<HTMLButtonElement>) => {
  const elementName = e.currentTarget.name;
  const element = document.getElementById(elementName);
  const tempElement = document.createElement('textarea');
  element && (tempElement.innerText = element.innerText);
  document.body.appendChild(tempElement);
  tempElement.select();
  document.execCommand('copy');
  tempElement.remove();
};

const Footer = () => {
  const {
    site: {
      siteMetadata: { mobileNumber, emailAddress },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mobileNumber
          emailAddress
        }
      }
    }
  `);
  return (
    <div className="footer">
      <div className="contact-wrapper">
        <div className="contact-info">Contact Information:</div>
        <div className="mobile">
          <span>
            Mobile:{' '}
            <a href={`tel:${mobileNumber}`} id="mobileNumber" rel="nofollow">
              {mobileNumber}
            </a>
          </span>
          <div className="mobile-actions">
            <button
              className="icon-circle fal copy"
              type="button"
              title="copy"
              name="mobileNumber"
              onClick={copyToClipBoard}
            />
            <a
              href="https://api.whatsapp.com/send?phone=6596472279"
              rel="noopener noreferrer"
              target="_blank"
            >
              <button
                className="icon-circle fab wa"
                type="button"
                title="WhatsApp"
              />
            </a>
          </div>
        </div>
        <span className="divider"> | </span>
        <div className="email">
          <span>
            <a href={`mailto:${emailAddress}`}>
              Email: <span id="emailAddress">{emailAddress}</span>
            </a>
          </span>
          <div className="email-actions">
            <button
              className="icon-circle fal copy"
              type="button"
              title="copy"
              name="emailAddress"
              onClick={copyToClipBoard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
